import styles from "./NotFound.module.scss";

function NotFound() {
  return (
    <div className={styles.container}>
      <div>404</div>
      not found...
    </div>
  );
}

export default NotFound;
